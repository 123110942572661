/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddNewRecoveryKeysPayload } from '../models/AddNewRecoveryKeysPayload';
import type { AddPasskeyEncryptedDEKPayload } from '../models/AddPasskeyEncryptedDEKPayload';
import type { AddPasswordEncryptedDEKPayload } from '../models/AddPasswordEncryptedDEKPayload';
import type { EnvelopeEncryptionConfig } from '../models/EnvelopeEncryptionConfig';
import type { PasskeyChallenge } from '../models/PasskeyChallenge';
import type { RecoverPasskeyPayload } from '../models/RecoverPasskeyPayload';
import type { RecoverPasswordPayload } from '../models/RecoverPasswordPayload';
import type { Recovery } from '../models/Recovery';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EncryptionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get envelope encryption keys
     * Get envelope encryption keys
     * @param therapistId
     * @param userId
     * @returns EnvelopeEncryptionConfig
     * @throws ApiError
     */
    public getKeys(
        therapistId: number,
        userId: number,
    ): CancelablePromise<EnvelopeEncryptionConfig> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/users/{userId}/encryption',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
        });
    }
    /**
     * Get recovery keys
     * Get recovery keys
     * @param therapistId
     * @param userId
     * @returns Recovery
     * @throws ApiError
     */
    public getRecoveryKeys(
        therapistId: number,
        userId: number,
    ): CancelablePromise<Recovery> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/users/{userId}/encryption/recovery',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
        });
    }
    /**
     * Get stable challenge for passkey encryption
     * Get stable challenge for passkey encryption
     * @param therapistId
     * @param userId
     * @returns PasskeyChallenge
     * @throws ApiError
     */
    public getPasskeyChallenge(
        therapistId: number,
        userId: number,
    ): CancelablePromise<PasskeyChallenge> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/users/{userId}/encryption/passkey-challenge',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
        });
    }
    /**
     * Add passkey encrypted data encryption key
     * Add passkey encrypted data encryption key
     * @param therapistId
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public addPasskeyEncryptedDek(
        therapistId: number,
        userId: number,
        requestBody: AddPasskeyEncryptedDEKPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/users/{userId}/encryption/add-passkey-encrypted-dek',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add password encrypted data encryption key
     * Add password encrypted data encryption key
     * @param therapistId
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public addPasswordEncryptedDek(
        therapistId: number,
        userId: number,
        requestBody: AddPasswordEncryptedDEKPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/users/{userId}/encryption/add-password-encrypted-dek',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Recover password-based envelope encryption
     * Recover password-based envelope encryption
     * @param therapistId
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public recoverPassword(
        therapistId: number,
        userId: number,
        requestBody: RecoverPasswordPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/users/{userId}/encryption/recover-password',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Recover passkey-based envelope encryption
     * Recover passkey-based envelope encryption
     * @param therapistId
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public recoverPasskey(
        therapistId: number,
        userId: number,
        requestBody: RecoverPasskeyPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/users/{userId}/encryption/recover-passkey',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add new recovery keys
     * Add new recovery keys
     * @param therapistId
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public addNewRecoveryKeys(
        therapistId: number,
        userId: number,
        requestBody: AddNewRecoveryKeysPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/users/{userId}/encryption/add-new-recovery-keys',
            path: {
                'therapistId': therapistId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
