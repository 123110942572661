'use client'

import { ApiError } from '@tebuto/api/therapists'
import { WhoAmI } from '@tebuto/api/therapists/models/WhoAmI'
import { useTherapistsApiRequest } from '@tebuto/functions/hooks'
import { transformTebutoLink } from '@tebuto/functions/links'
import { LoadingScreen } from '@tebuto/layout/LoadingScreen'
import PageError from '@tebuto/layout/PageError'
import { usePathname, useRouter } from 'next/navigation'
import { createContext } from 'react'

export const CurrentUserContext = createContext<WhoAmI | null>(null)

export function CurrentUserGuard({ children }: { children: React.ReactNode }): JSX.Element {
    const router = useRouter()
    const pathname = usePathname()

    const { data: userContext, showLoading, error } = useTherapistsApiRequest('who-am-i', api => api.whoAmI.whoAmI(), { onError: () => {} })

    if (showLoading) {
        return <LoadingScreen />
    }

    if (error || !userContext) {
        if (error instanceof ApiError && error.status === 401) {
            const authorizeThenReturnUrl = new URL(`${process.env.NEXT_PUBLIC_THERAPISTS_AUTHORIZE_URL}`)
            authorizeThenReturnUrl.searchParams.set('returnTo', transformTebutoLink(pathname))
            router.push(authorizeThenReturnUrl.toString())
            return <>Redirecting...</>
        }
        return <PageError reset={() => router.refresh()} />
    }
    return <CurrentUserContext.Provider value={userContext}>{children}</CurrentUserContext.Provider>
}
