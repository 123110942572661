/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HandleMoveEventRuleActionData } from './HandleMoveEventRuleActionData';
export type HandleMoveEventRuleActionPayload = {
    payload: HandleMoveEventRuleActionData;
    action: HandleMoveEventRuleActionPayload.action;
};
export namespace HandleMoveEventRuleActionPayload {
    export enum action {
        MOVE_RULE = 'move-rule',
        MOVE_OCCURRENCE = 'move-occurrence',
        SKIP_OCCURRENCE = 'skip-occurrence',
        UNDO = 'undo',
    }
}

