/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { UpdateAddressPayload } from '../models/UpdateAddressPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AddressService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Updates the address of a therapist
     * Update Address
     * @param therapistId
     * @param requestBody
     * @returns Address
     * @throws ApiError
     */
    public updateAddress(
        therapistId: number,
        requestBody: UpdateAddressPayload,
    ): CancelablePromise<Address> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/therapists/{therapistId}/address',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
