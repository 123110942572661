/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Contract } from './Contract';
import type { TherapistPrecondition } from './TherapistPrecondition';
export type WhoAmITherapist = {
    id: number;
    name: string;
    uuid: string;
    slug: string;
    type: WhoAmITherapist.type;
    image: string | null;
    welcomeTourDone: boolean;
    contract: Contract;
    preconditions: Array<TherapistPrecondition>;
};
export namespace WhoAmITherapist {
    export enum type {
        THERAPIST = 'therapist',
        CONSULTANT = 'consultant',
        COACH = 'coach',
        OTHER = 'other',
    }
}

