/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MinimalWhoAmITherapist = {
    id: number;
    name: string;
    slug: string;
    type: MinimalWhoAmITherapist.type;
    image: string | null;
    isActive: boolean;
};
export namespace MinimalWhoAmITherapist {
    export enum type {
        THERAPIST = 'therapist',
        CONSULTANT = 'consultant',
        COACH = 'coach',
        OTHER = 'other',
    }
}

