/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Passkey } from './Passkey';
export type EnvelopeEncryptionConfig = {
    encryptedDEK: string;
    iv: string;
    salt: string;
    type: EnvelopeEncryptionConfig.type;
    passkey?: Passkey;
};
export namespace EnvelopeEncryptionConfig {
    export enum type {
        PASSWORD = 'password',
        PASSKEY = 'passkey',
    }
}

