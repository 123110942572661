/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HandleSkipOccurrenceEventRuleActionData } from './HandleSkipOccurrenceEventRuleActionData';
export type HandleSkipOccurrenceEventRuleActionPayload = {
    payload: HandleSkipOccurrenceEventRuleActionData;
    action: HandleSkipOccurrenceEventRuleActionPayload.action;
};
export namespace HandleSkipOccurrenceEventRuleActionPayload {
    export enum action {
        MOVE_RULE = 'move-rule',
        MOVE_OCCURRENCE = 'move-occurrence',
        SKIP_OCCURRENCE = 'skip-occurrence',
        UNDO = 'undo',
    }
}

