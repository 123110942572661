/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventCategory } from '../models/EventCategory';
import type { EventCategoryPayload } from '../models/EventCategoryPayload';
import type { UpdateEventCategoryPayload } from '../models/UpdateEventCategoryPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventCategoriesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all event categories
     * Get all event categories
     * @param therapistId
     * @returns EventCategory
     * @throws ApiError
     */
    public getAllEventCategories(
        therapistId: number,
    ): CancelablePromise<Array<EventCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/event-categories',
            path: {
                'therapistId': therapistId,
            },
        });
    }
    /**
     * Add event category
     * Add event category
     * @param therapistId
     * @param requestBody
     * @returns EventCategory
     * @throws ApiError
     */
    public addEventCategory(
        therapistId: number,
        requestBody: EventCategoryPayload,
    ): CancelablePromise<EventCategory> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-categories',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update event category
     * Update event category
     * @param therapistId
     * @param eventCategoryId
     * @param requestBody
     * @returns EventCategory
     * @throws ApiError
     */
    public updateEventCategory(
        therapistId: number,
        eventCategoryId: number,
        requestBody: UpdateEventCategoryPayload,
    ): CancelablePromise<EventCategory> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/therapists/{therapistId}/event-categories/{eventCategoryId}',
            path: {
                'therapistId': therapistId,
                'eventCategoryId': eventCategoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete event category
     * Delete event category
     * @param therapistId
     * @param eventCategoryId
     * @returns void
     * @throws ApiError
     */
    public deleteEventCategory(
        therapistId: number,
        eventCategoryId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/event-categories/{eventCategoryId}',
            path: {
                'therapistId': therapistId,
                'eventCategoryId': eventCategoryId,
            },
        });
    }
}
