/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEventRuleConflictsResult } from '../models/AddEventRuleConflictsResult';
import type { CheckEventRuleExceptionDeletionPayload } from '../models/CheckEventRuleExceptionDeletionPayload';
import type { CheckEventRuleExceptionDeletionResult } from '../models/CheckEventRuleExceptionDeletionResult';
import type { CheckEventRuleMoveOptionsPayload } from '../models/CheckEventRuleMoveOptionsPayload';
import type { CheckEventRuleMoveOptionsResult } from '../models/CheckEventRuleMoveOptionsResult';
import type { EventRule } from '../models/EventRule';
import type { EventRulePayload } from '../models/EventRulePayload';
import type { HandleMoveEventRuleActionPayload } from '../models/HandleMoveEventRuleActionPayload';
import type { HandleMoveOccurrenceEventRuleActionPayload } from '../models/HandleMoveOccurrenceEventRuleActionPayload';
import type { HandleSkipOccurrenceEventRuleActionPayload } from '../models/HandleSkipOccurrenceEventRuleActionPayload';
import type { HandleUndoEventRuleActionPayload } from '../models/HandleUndoEventRuleActionPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventRulesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get event rule
     * Get event rule
     * @param therapistId
     * @param eventRuleId
     * @returns EventRule
     * @throws ApiError
     */
    public getEventRule(
        therapistId: number,
        eventRuleId: number,
    ): CancelablePromise<EventRule> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/event-rules/{eventRuleId}',
            path: {
                'therapistId': therapistId,
                'eventRuleId': eventRuleId,
            },
        });
    }
    /**
     * Handle event rule action
     * Handle event rule action
     * @param therapistId
     * @param eventRuleId
     * @param requestBody
     * @returns EventRule
     * @throws ApiError
     */
    public handleEventRuleAction(
        therapistId: number,
        eventRuleId: number,
        requestBody: (HandleMoveEventRuleActionPayload | HandleMoveOccurrenceEventRuleActionPayload | HandleSkipOccurrenceEventRuleActionPayload | HandleUndoEventRuleActionPayload),
    ): CancelablePromise<EventRule> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-rules/{eventRuleId}',
            path: {
                'therapistId': therapistId,
                'eventRuleId': eventRuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete event rule
     * Delete event rule
     * @param therapistId
     * @param eventRuleId
     * @returns void
     * @throws ApiError
     */
    public deleteEventRule(
        therapistId: number,
        eventRuleId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/event-rules/{eventRuleId}',
            path: {
                'therapistId': therapistId,
                'eventRuleId': eventRuleId,
            },
        });
    }
    /**
     * Get all event rules
     * Get all event rules
     * @param therapistId
     * @param start Earliest start date to filter for. Optional
     * @param end Latest end date to filter for. Optional
     * @returns EventRule
     * @throws ApiError
     */
    public getAllEventRules(
        therapistId: number,
        start?: string,
        end?: string,
    ): CancelablePromise<Array<EventRule>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/event-rules',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * Add event rule
     * Add event rule
     * @param therapistId
     * @param requestBody
     * @returns EventRule
     * @throws ApiError
     */
    public addEventRule(
        therapistId: number,
        requestBody: EventRulePayload,
    ): CancelablePromise<EventRule> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-rules',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get conflicts for adding an event rule
     * Get conflicts for adding an event rule
     * @param therapistId
     * @param requestBody
     * @returns AddEventRuleConflictsResult
     * @throws ApiError
     */
    public getAddEventRuleConflicts(
        therapistId: number,
        requestBody: EventRulePayload,
    ): CancelablePromise<AddEventRuleConflictsResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-rules/add-event-rule-conflicts',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the options for moving an event rule
     * Get the options for moving an event rule
     * @param therapistId
     * @param requestBody
     * @returns CheckEventRuleMoveOptionsResult
     * @throws ApiError
     */
    public getEventRuleMoveOptions(
        therapistId: number,
        requestBody: CheckEventRuleMoveOptionsPayload,
    ): CancelablePromise<CheckEventRuleMoveOptionsResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-rules/check-move-options',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the options for deleting an event rule exception
     * Get the options for deleting an event rule exception
     * @param therapistId
     * @param requestBody
     * @returns CheckEventRuleExceptionDeletionResult
     * @throws ApiError
     */
    public getExceptionDeletionOptions(
        therapistId: number,
        requestBody: CheckEventRuleExceptionDeletionPayload,
    ): CancelablePromise<CheckEventRuleExceptionDeletionResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-rules/check-exception-deletion-options',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
