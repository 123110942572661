/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateTime } from './DateTime';
export type HandleMoveEventRuleActionData = {
    diffMilliseconds: number;
    newEndDate?: DateTime;
    futureBookingsDecision?: HandleMoveEventRuleActionData.futureBookingsDecision;
};
export namespace HandleMoveEventRuleActionData {
    export enum futureBookingsDecision {
        MOVE = 'MOVE',
        CANCEL = 'CANCEL',
        KEEP = 'KEEP',
    }
}

