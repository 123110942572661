'use client'

import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'

export function LoadingScreen({ show = true }: { show?: boolean }) {
    const [display, setDisplay] = useState(show)

    useEffect(() => {
        if (show) {
            setDisplay(true)
            // Add a slight delay before applying the class to ensure the element is visible and ready
            const timer = setTimeout(() => {
                const svgElement = document.querySelector('svg')
                if (svgElement) {
                    svgElement.classList.add('active')
                }
            }, 10) // Minimal delay to sync with CSS transitions and DOM updates
            return () => clearTimeout(timer)
        }
        const svgElement = document.querySelector('svg')
        if (svgElement) {
            svgElement.classList.remove('active')
        }
        // Delay the hiding of the screen to allow the animation to complete
        const timer = setTimeout(() => setDisplay(false), 700) // This should match the CSS transition time
        return () => clearTimeout(timer)
    }, [show])

    return (
        <Transition
            show={display}
            enter="transition-opacity duration-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-700"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="w-screen h-screen absolute z-[100] bg-white top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
                <svg width="250" height="250" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="loading">
                    <title id="loading">Wird geladen...</title>
                    <path
                        d="M5.99992 44.5006C9.49995 41.5002 13 41.0002 15.9999 43.5006C17.668 44.891 27.066 54.3199 35.0817 62.4179C51.6335 47.8491 79.0397 23.7692 82.4999 21.0007C87.5 17.0001 91.5 17.5 94.9999 21.0004C98.4998 24.5008 98 29.5002 94.751 32.2414C92.4885 34.1503 64.0371 59.3459 46.9695 74.4691C46.9897 74.4897 47 74.5002 47 74.5002L34.251 85.7414C34.251 85.7414 6.49985 58.0011 3.99992 54.5006C1.49999 51.0002 2.49988 47.5011 5.99992 44.5006Z"
                        fill="#00B4A9"
                        className="svg-elem-1"
                    />
                    <path
                        d="M34.251 85.7414L47 74.5002C47 74.5002 18.9998 46.0011 15.9999 43.5006C13 41.0002 9.49995 41.5002 5.99992 44.5006C2.49988 47.5011 1.49999 51.0002 3.99992 54.5006C6.49985 58.0011 34.251 85.7414 34.251 85.7414ZM34.251 85.7414L22.4999 73.5006C22.4999 73.5006 77.4998 25.0012 82.4999 21.0007C87.5 17.0001 91.5 17.5 94.9999 21.0004C98.4998 24.5008 98 29.5002 94.751 32.2414C91.5021 34.9826 34.251 85.7414 34.251 85.7414Z"
                        stroke="#00B4A9"
                        className="svg-elem-2"
                    />
                </svg>
            </div>
        </Transition>
    )
}
