'use client'

import { PublicTherapist } from '@tebuto/api/therapists/models/PublicTherapist'
import { Button } from '@tebuto/buttons/Button'
import { useClientsApiRequest, useCurrentClient } from '@tebuto/functions/hooks'
import { useComponentTranslation } from '@tebuto/functions/translation.components'
import Footer from '@tebuto/layout/client/Footer'
import MinimalNavbar from '@tebuto/layout/client/MinimalNavbar'
import { setTherapistId } from '@tebuto/state/therapist'
import Heading from '@tebuto/typography/Heading'
import LargeText from '@tebuto/typography/LargeText'
import MediumText from '@tebuto/typography/MediumText'
import SmallText from '@tebuto/typography/SmallText'
import Image from 'next/image'
import { useDispatch } from 'react-redux'

export default function ClientTherapistSelection() {
    const currentClient = useCurrentClient()
    return (
        <div className="min-h-screen flex flex-col bg-primary-25">
            <MinimalNavbar />
            <main className="flex-grow max-w-5xl w-full mx-auto lg:px-0 px-6 lg:py-12 py-6">
                {currentClient.therapists.length === 0 ? <NoTherapistSelected /> : <TherapistsAvailable />}
            </main>
            <Footer />
        </div>
    )
}

function NoTherapistSelected() {
    const { t } = useComponentTranslation()
    return (
        <>
            <Heading level={1}>{t('guards.clientTherapistSelection.noTherapist.title')}</Heading>
            <div className="grid gap-6 my-10">
                <MediumText>{t('guards.clientTherapistSelection.noTherapist.description1')}</MediumText>
                <MediumText>{t('guards.clientTherapistSelection.noTherapist.description2')}</MediumText>
            </div>
        </>
    )
}

function TherapistsAvailable() {
    const { t } = useComponentTranslation()
    const currentClient = useCurrentClient()
    return (
        <>
            <Heading level={1}>{t('guards.clientTherapistSelection.therapistsAvailable.title')}</Heading>
            <MediumText className="mt-4">{t('guards.clientTherapistSelection.therapistsAvailable.description')}</MediumText>
            <div className="flex flex-col gap-10 lg:mt-20 mt-10 max-w-3xl mx-auto">
                {currentClient.therapists.map(therapist => (
                    <TherapistOption key={therapist.id} id={therapist.id} />
                ))}
            </div>
        </>
    )
}

function TherapistOption({ id }: { id: number }) {
    const dispatch = useDispatch()
    const { t } = useComponentTranslation()
    const { data: therapist, isLoading } = useClientsApiRequest(['therapist', id], api => api.therapists.getTherapist(id))

    if (isLoading || !therapist) {
        return <></>
    }

    let ownerWord = t('guards.clientTherapistSelection.therapistsAvailable.owner')
    if (therapist.gender === PublicTherapist.gender.MALE) {
        ownerWord = t('guards.clientTherapistSelection.therapistsAvailable.owner', { context: 'male' })
    }
    if (therapist.gender === PublicTherapist.gender.FEMALE) {
        ownerWord = t('guards.clientTherapistSelection.therapistsAvailable.owner', { context: 'female' })
    }

    return (
        <div className="relative overflow-hidden border border-gray-300 rounded-lg bg-white shadow-md justify-center grid sm:grid-cols-12">
            {/* Image */}
            <div className="h-full w-full flex relative m-0 p-0 md:col-span-3 sm:col-span-4">
                <Image
                    src={therapist.image || '/assets/profile_blank.svg'}
                    alt={therapist.name}
                    width={100}
                    height={100}
                    className="w-auto h-auto object-top object-cover mx-auto"
                />
            </div>
            <div className="grid py-5 px-8 md:col-span-9 sm:col-span-8">
                {/* Text */}
                <div className="grid py-4 gap-4 flex-grow">
                    <div className="flex-col">
                        <LargeText className="font-semibold">{therapist.name}</LargeText>
                        <div className="flex flex-wrap gap-1">
                            <SmallText>{therapist.address.streetAndNumber},</SmallText>
                            {therapist.address.additionalInformation && <SmallText>{therapist.address.additionalInformation},</SmallText>}
                            <SmallText>
                                {therapist.address.city.zip} {therapist.address.city.name}
                            </SmallText>
                        </div>
                    </div>
                </div>
                {/* Button */}
                <Button title={t('guards.clientTherapistSelection.therapistsAvailable.select')} onClick={() => dispatch(setTherapistId(id))} className="w-max">
                    {t('guards.clientTherapistSelection.therapistsAvailable.select')}
                </Button>
            </div>
        </div>
    )
}
