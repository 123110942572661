/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateEventCategoryPayload = {
    name: string;
    description: string | null;
    color: string;
    price: string;
    outageFeeEnabled: boolean;
    outageFeeHours: number | null;
    outageFeePrice: string | null;
    currency: UpdateEventCategoryPayload.currency;
    location: UpdateEventCategoryPayload.location;
    publicBookingEnabled?: boolean;
    privateBookingEnabled?: boolean;
};
export namespace UpdateEventCategoryPayload {
    export enum currency {
        EUR = 'EUR',
        CHF = 'CHF',
    }
    export enum location {
        VIRTUAL = 'virtual',
        ONSITE = 'onsite',
        NOT_FIXED = 'not-fixed',
    }
}

