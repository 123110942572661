'use client'

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { Bars3Icon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useIsScreenWidthBelow } from '@tebuto/functions/responsiveness'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export interface SubMenuEntryProps {
    name: string
    description: string
    href: string
    isAbsolute?: boolean
    icon: any
}
export interface SubMenuCTAProps {
    name: string
    href: string
    icon: any
}

export interface SubMenuProps {
    items: SubMenuEntryProps[]
    layout?: '2rows' | '1row'
    cta?: SubMenuCTAProps[]
}

export interface MenuEntry {
    name: string
    href: string
    mobileHref?: string
    mobileBehaviour?: 'flat' | 'titleOnly' | 'default'
    subMenu?: SubMenuProps
    isAbsolute?: boolean
    id?: string
    highlight?: boolean
}

export interface IMenu {
    leftItems: MenuEntry[]
    rightItems: MenuEntry[]
    additionalMobileItems?: MenuEntry[]
    baseUrl: string
    id?: string
}

export function Menu({ leftItems, rightItems, baseUrl, id, additionalMobileItems }: IMenu) {
    const activeRoute = usePathname()
    const [open, setOpen] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)
    const isMobile = useIsScreenWidthBelow(1024)
    const [isVisible, setIsVisible] = useState(true)
    const [lastScrollY, setLastScrollY] = useState(0)

    function SubMenu({ subMenu, name, isRight }: { subMenu: SubMenuProps; name: string; isRight?: boolean }) {
        return (
            <Popover className="relative inline">
                <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6">
                    <span className={'px-2 py-2 font-semibold text-white rounded-md xl:text-xs text-xxs'}>{name}</span>
                    <ChevronDownIcon className={'h-5 w-5 text-white mr-2 transition-transform transform duration-300 ui-open:rotate-180'} aria-hidden="true" />
                </PopoverButton>
                <Transition
                    enter="transition ease-out duration-1000"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className={`absolute left-0 mt-4 flex w-screen max-w-max ${isRight ? '-translate-x-1/2' : '-translate-x-1/4'}`}>
                        <div
                            className={`w-screen flex-auto overflow-hidden rounded-3xl bg-gradient-primary-decent border border-primary-800 text-sm leading-6 shadow-xl ${subMenu.layout === '2rows' ? 'max-w-5xl' : 'max-w-lg'}`}
                        >
                            <div className={`p-4 grid  ${subMenu.layout === '2rows' ? 'grid-cols-2' : 'grid-cols-1'}`}>
                                {subMenu?.items?.map(item => (
                                    <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-primary-500/50 duration-300 transition-all">
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-primary-500/50 group-hover:bg-primary-700 duration-300 transition-all">
                                            <item.icon className="h-6 w-6 text-primary-100 group-hover:text-primary-50" aria-hidden="true" />
                                        </div>
                                        <div>
                                            <Link href={item.href.startsWith('http') ? item.href : baseUrl + item.href} className="font-semibold text-white text-xs">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="text-xs text-primary-100">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-2 divide-x divide-primary-800 border-t border-primary-800 bg-primary-700">
                                {subMenu?.cta?.map(item => (
                                    <Link
                                        key={item.name}
                                        href={item.href.startsWith('http') ? item.href : baseUrl + item.href}
                                        className="flex items-center justify-center gap-x-2.5 p-3 font-semibold hover:bg-primary-600"
                                    >
                                        <item.icon className="h-5 w-5 flex-none text-primary-200" aria-hidden="true" />
                                        <p className="text-xs text-primary-100">{item.name}</p>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>
        )
    }

    function ItemEntry({ item, className = '' }: { item: MenuEntry; className?: string }) {
        const styles = `px-3 max-menu:px-2 py-1.5 font-semibold xl:text-xs text-xxs rounded-md text-white block ${className} ${item.highlight ? 'bg-primary-50 py-2 px-3 !text-primary-600 font-semibold !shadow-2xl' : ''}`

        return (
            <Link
                id={item.id}
                key={item.name}
                onClick={isMobile && item.href !== '#' ? () => setOpen(false) : undefined}
                href={item.isAbsolute ? item.href : baseUrl + item.href}
                className={styles}
                aria-current={item.href === activeRoute ? 'page' : undefined}
                shallow
            >
                {item.name}
            </Link>
        )
    }

    useEffect(() => {
        if (open) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
        return () => document.body.classList.remove('overflow-hidden')
    }, [open])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY
            const scrollThreshold = 5 // Avoid reacting to tiny scrolls

            // Always show menu when at the top of the page
            if (currentScrollY <= 0) {
                setIsVisible(true)
                setLastScrollY(0)
                return
            }

            if (window.scrollY > 50) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }

            if (currentScrollY > lastScrollY + scrollThreshold) {
                setIsVisible(false) // Scrolling down
            } else if (currentScrollY < lastScrollY - scrollThreshold) {
                setIsVisible(true) // Scrolling up
            }

            setLastScrollY(currentScrollY)
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [lastScrollY])

    return (
        <nav className={'text-primary-50 relative z-20 h-20'} id={id}>
            <div className={`fixed w-full duration-300 transition-all ${isScrolled ? 'h-16' : 'h-20'} ${isVisible ? 'top-0' : '-top-40'}`}>
                <div className={`max-w-full mx-auto px-12 w-full bg-primary-700 shadow ${isScrolled ? 'bg-opacity-90' : ''}`}>
                    <div className={`flex items-center duration-150 transition-all justify-center ${isScrolled ? 'h-16' : 'h-20'}`}>
                        <div className="absolute inset-y-0 left-0 top-0 flex items-center lg:hidden">
                            {/* Mobile menu button*/}
                            <button
                                type="button"
                                onClick={() => setOpen(!open)}
                                className="inline-flex items-center justify-center p-2 ml-4 rounded-md text-white hover:text-primary-50 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            >
                                <span className="sr-only">Hauptmenü</span>
                                {open ? <XMarkIcon className="block h-8 w-8" aria-hidden="true" /> : <Bars3Icon className="block h-8 w-8" aria-hidden="true" />}
                            </button>
                        </div>

                        <Link href={`${baseUrl}`}>
                            <Image
                                height={isScrolled ? 16 : 24}
                                width={125}
                                className={`max-lg:mx-auto w-auto transition-all duration-150 xl:mr-16 lg:mr-4 ${isScrolled ? 'h-4' : 'xl:h-6 h-4'}`}
                                src="/assets/logoDarkBg.svg"
                                alt="Tebuto Logo"
                                priority
                            />
                        </Link>

                        <div className="lg:w-full lg:flex lg:justify-between">
                            <div className="absolute inset-y-0 left-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 my-4 z-20 hidden lg:flex 2xl:gap-6 xl:gap-3 gap-2">
                                {leftItems.map(item => (
                                    <div key={item.name}>{item.subMenu ? <SubMenu subMenu={item.subMenu} name={item.name} /> : <ItemEntry item={item} />}</div>
                                ))}
                            </div>
                            <div className="absolute inset-y-0 left-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 my-4 z-20 hidden lg:flex 2xl:gap-6 xl:gap-3 gap-2">
                                {rightItems.map(item => (
                                    <div key={item.name}>{item.subMenu ? <SubMenu subMenu={item.subMenu} name={item.name} /> : <ItemEntry item={item} />}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Transition
                    show={open}
                    enter="transition-transform transition-opacity duration-200 ease-out"
                    enterFrom="-translate-x-full opacity-0"
                    enterTo="translate-x-0 opacity-100"
                    leave="transition-transform transition-opacity duration-300 ease-in"
                    leaveFrom="translate-x-0 opacity-100"
                    leaveTo="-translate-x-1/2 opacity-0"
                >
                    <div className="lg:hidden bg-primary-600 fixed w-full z-20 h-full">
                        <div className="px-2 pt-4 divide-y divide-primary-500 flex flex-col gap-2 max-h-[calc(100vh-6rem)] overflow-auto">
                            <MobileMenu />
                        </div>
                    </div>
                </Transition>
            </div>
        </nav>
    )

    function MobileMenu() {
        return rightItems
            .concat(additionalMobileItems ? additionalMobileItems : [])
            .concat(leftItems)
            .map((item, index) => {
                if (item.mobileBehaviour === 'flat' && item.subMenu) {
                    return item.subMenu.items.map(subItem => (
                        <div key={`menu-item-${index}-${item.name}-${item.id}`}>
                            <div className="pt-2" key={subItem.name}>
                                <ItemEntry item={subItem} />
                            </div>
                        </div>
                    ))
                }

                return (
                    <div key={`menu-item-${index}-${item.name}-${item.id}`}>
                        {item.subMenu && item.mobileBehaviour === 'titleOnly' ? (
                            <div className="pt-2" key={item.name}>
                                <ItemEntry item={{ ...item, href: item.mobileHref as string }} />
                            </div>
                        ) : (
                            <div className="pt-2" key={item.name}>
                                <ItemEntry item={item} />
                            </div>
                        )}
                        {item.subMenu && item.mobileBehaviour === 'default' && (
                            <div className="px-2 flex flex-col gap-1 mt-2 mb-1">
                                {item.subMenu.items.map(subItem => (
                                    <div className="bg-primary-700/40 border border-primary-700/70 flex items-center py-0.5 px-2 rounded-lg" key={subItem.name}>
                                        <subItem.icon className="h-5 w-5" /> <ItemEntry item={subItem} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )
            })
    }
}
