/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreatePortalSessionPayload = {
    upgradeTo?: CreatePortalSessionPayload.upgradeTo;
    returnTo?: string;
};
export namespace CreatePortalSessionPayload {
    export enum upgradeTo {
        FREE = 'free',
        PAID = 'paid',
    }
}

