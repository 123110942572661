'use client'

import { useTherapistsApiRequest } from '@tebuto/functions/hooks'
import SWRConfiguration from '@tebuto/layout/SWRConfiguration'
import { createContext } from 'react'

export const CurrentLoginStatusContext = createContext<boolean>(false)

export function LoginStatusGuard({ children }: { children: React.ReactNode }) {
    return (
        <SWRConfiguration>
            <Provider>{children}</Provider>
        </SWRConfiguration>
    )
}

function Provider({ children }: { children: React.ReactNode }) {
    const { data: userContext } = useTherapistsApiRequest('who-am-i', api => api.whoAmI.whoAmI(), { handleUnauthorized: false, onError: () => undefined })

    if (userContext) return <CurrentLoginStatusContext.Provider value={true}>{children}</CurrentLoginStatusContext.Provider>

    return <CurrentLoginStatusContext.Provider value={false}>{children}</CurrentLoginStatusContext.Provider>
}
