'use client'

import { useComponentTranslation } from '@tebuto/functions/translation.components'
import { useMemo } from 'react'
import { Menu, MenuEntry } from '../Menu'

export default function Navbar() {
    const { t } = useComponentTranslation()

    const menuItems: MenuEntry[] = useMemo(
        () => [
            {
                name: t('layout.client.settings'),
                href: '/einstellungen'
            },
            {
                name: t('layout.client.logout'),
                href: `${process.env.NEXT_PUBLIC_CLIENTS_API_URL}/auth/logout`,
                isAbsolute: true
            }
        ],
        []
    )

    return <Menu rightItems={menuItems} leftItems={[]} baseUrl={`${process.env.NEXT_PUBLIC_CLIENTS_BASE_URL}`} />
}
